const defaultState = {
  list: [],
  current: {}
};

export default function(state = defaultState, action = {}) {
  switch (action.type) {
    case "HISTORY.CLEAR_CURRENT":
      return clearCurrent(state, action);
    case "HISTORY.SET_CURRENT":
      return setCurrent(state, action);
    case "HISTORY.DELETED":
      return handleDeletion(state, action);
    case "HISTORY.CLEAR_LIST":
      return clearList(state, action);
    case "HISTORY.SET_LIST":
      return setList(state, action);
    default:
      return state;
  }
}

function clearCurrent(state, action) {
  return { ...state, current: {} };
}

function setCurrent(state, action) {
  return { ...state, current: action.session };
}

function handleDeletion(state, action) {
  return {
    ...state,
    list: state.list.filter((s) => s._id !== action.id)
  };
}

function clearList(state, action) {
  return { ...state, list: [] };
}

function setList(state, action) {
  return { ...state, list: action.list };
}
