import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export class ProtectedRoute extends React.Component {
  render() {
    if (this.props.isAuthenticated) {
      return <Route {...this.props} />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.func,
  render: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.user.userName !== null
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
