import { httpRequest } from "./common";

export function logIn(userName, password) {
  return async function(dispatch, getState) {
    const url = "/api/auth/login";
    const options = {
      method: "POST",
      body: JSON.stringify({ userName, password }),
      headers: { "content-type": "application/json" }
    };
    const onSuccess = function(json) {
      dispatch(tokenReceived(json.token));
    };
    await httpRequest(dispatch, getState, url, options, onSuccess);
  };
}
export function tokenReceived(token) {
  return { type: "AUTH.TOKEN_RECEIVED", token };
}

export function logOut() {
  return { type: "AUTH.LOG_OUT" };
}
