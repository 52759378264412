const defaultState = {
  quizList: [],
  currentQuiz: {}
};

export default function(state = defaultState, action = {}) {
  switch (action.type) {
    case "QUIZ.CLEAR_CURRENT":
      return clearQuiz(state, action);
    case "QUIZ.SET_CURRENT":
      return setCurrentQuiz(state, action);
    case "QUIZ.DELETED":
      return quizDeleted(state, action);
    case "QUIZ.LIST_RECEIVED":
      return setQuizList(state, action);
    case "QUIZ.QUESTION.SAVED":
      return quizQuestionSaved(state, action);
    case "QUIZ.QUESTION.DELETED":
      return quizQuestionDeleted(state, action);
    default:
      return state;
  }
}

function clearQuiz(state, action) {
  return { ...defaultState, quizList: [...state.quizList] };
}

function setCurrentQuiz(state, action) {
  return { ...state, currentQuiz: action.quiz };
}

function quizDeleted(state, action) {
  return {
    ...state,
    quizList: state.quizList.filter((q) => q._id !== action.id)
  };
}

function setQuizList(state, action) {
  return { ...state, quizList: action.quizList };
}

function quizQuestionSaved(state, action) {
  const question = action.question;
  const currentQuiz = { ...state.currentQuiz };
  currentQuiz.questions = currentQuiz.questions.filter((q) => q.index !== question.index);
  currentQuiz.questions.push(question);
  return { ...state, currentQuiz };
}

function quizQuestionDeleted(state, action) {
  const currentQuiz = { ...state.currentQuiz };
  currentQuiz.questions = currentQuiz.questions.filter((q) => q.index !== action.index);
  return { ...state, currentQuiz };
}
