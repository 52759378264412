import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Icon, Responsive } from "semantic-ui-react";
import { logOut } from "../../actions/auth.actions";

export class TopMenu extends React.Component {
  render() {
    return (
      <Menu fixed="top" inverted>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Menu.Item as={Link} to="/" header>
            AberQuiz
          </Menu.Item>
        </Responsive>
        {this.leftMenu()}
        <Menu.Menu position="right">{this.rightMenu()}</Menu.Menu>
      </Menu>
    );
  }

  leftMenu() {
    if (this.props.userName) {
      return (
        <React.Fragment>
          <Menu.Item as={Link} to="/manage">
            <Icon name="list" />
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>Quizzes</Responsive>
          </Menu.Item>
        </React.Fragment>
      );
    }
  }

  rightMenu() {
    if (this.props.userName) {
      return (
        <React.Fragment>
          <Menu.Item as="a">{this.props.userName}</Menu.Item>
          <Menu.Item as="a" onClick={this.props.logOut}>
            Log Out
          </Menu.Item>
        </React.Fragment>
      );
    } else {
      return (
        <Menu.Item as={Link} to="/login">
          Log In
        </Menu.Item>
      );
    }
  }
}

TopMenu.propTypes = {
  userName: PropTypes.string,
  logOut: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userName: state.auth.user.userName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => dispatch(logOut())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
