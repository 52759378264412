export function loadState() {
  try {
    const state = localStorage.getItem("state");
    if (state === null) {
      return undefined;
    } else {
      return JSON.parse(state);
    }
  } catch (e) {
    return undefined;
  }
}

export function saveState(state) {
  try {
    localStorage.setItem("state", JSON.stringify(state));
  } catch (err) {
    console.error(err);
  }
}
