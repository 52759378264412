import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./configureStore";
import Root from "./components/Root";
import { loadState, saveState } from "./localStorage";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./styles.css";

const store = configureStore(loadState());

store.subscribe(function() {
  const auth = { ...store.getState().auth };
  delete auth.message;
  delete auth.status;
  saveState({ auth });
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
