const defaultState = {
  isPending: false,
  isError: false,
  errors: []
};

export default function(state = defaultState, action = {}) {
  switch (action.type) {
    case "NETWORK_REQUEST":
      return handleNetworkRequest(state, action);
    default:
      return state;
  }
}

function handleNetworkRequest(state, action) {
  switch (action.status) {
    case "PENDING":
      return {
        ...state,
        isPending: true,
        isError: false,
        errors: []
      };
    case "SUCCESS":
      return {
        ...state,
        isPending: false,
        isError: false,
        errors: []
      };
    case "ERROR":
      return {
        ...state,
        isPending: false,
        isError: true,
        errors: action.errors
      };
    default:
      return state;
  }
}
