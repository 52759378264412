import React from "react";
import { Container } from "semantic-ui-react";
import TopMenu from "./TopMenu";
import RouterOutput from "./RouterOutput";

export default class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TopMenu />
        <Container style={{ marginTop: "4em", marginBottom: "1em" }}>
          <RouterOutput />
        </Container>
      </React.Fragment>
    );
  }
}
