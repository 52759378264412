import React from "react";
import App from "./core/App";

export default class Root extends React.Component {
  additionalItems() {
    if (process.env.NODE_ENV === "development") {
      // use require() here instead of a top-level import
      // to completely avoid importing unless in development
      const DevTools = require("./DevTools").default;
      return <DevTools />;
    }
  }

  render() {
    return (
      <React.Fragment>
        <App />
        {this.additionalItems()}
      </React.Fragment>
    );
  }
}
