import jwt from "jsonwebtoken";

const defaultState = {
  token: null,
  exp: null,
  user: {
    userName: null
  }
};

function isExpired(exp) {
  return exp < Date.now() / 1000;
}

export default function(state = defaultState, action = {}) {
  switch (action.type) {
    case "AUTH.TOKEN_RECEIVED":
      let decoded = jwt.decode(action.token);
      return {
        ...defaultState,
        token: action.token,
        exp: decoded.exp,
        user: decoded.user
      };
    case "AUTH.LOG_OUT":
      return { ...defaultState };
    default:
      if (state.exp && isExpired(state.exp)) {
        return { ...defaultState };
      } else {
        return state;
      }
  }
}
