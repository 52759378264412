export function requestPending() {
  return { type: "NETWORK_REQUEST", status: "PENDING" };
}

export function requestSuccess() {
  return { type: "NETWORK_REQUEST", status: "SUCCESS" };
}

export function requestError(errors) {
  return { type: "NETWORK_REQUEST", status: "ERROR", errors };
}
