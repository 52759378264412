import authReducer from "./auth.reducer";
import quizReducer from "./quiz.reducer";
import historyReducer from "./history.reducer";
import requestReducer from "./request.reducer";

export default function(state = {}, action) {
  return {
    auth: authReducer(state.auth, action),
    quiz: quizReducer(state.quiz, action),
    history: historyReducer(state.history, action),
    request: requestReducer(state.request, action)
  };
}
