import React from "react";
import Loadable from "react-loadable";
import { Switch, Route } from "react-router-dom";
import PageLoading from "./PageLoading";
import ProtectedRoute from "./ProtectedRoute";

const loadableOptions = {
  loading: PageLoading,
  delay: 200,
  timeout: 20000
};

const SessionJoiner = Loadable({
  loader: () => import("../quiz-session/SessionJoiner"),
  ...loadableOptions
});

const LogIn = Loadable({
  loader: () => import("../auth/LogIn"),
  ...loadableOptions
});

const QuizList = Loadable({
  loader: () => import("../quiz-management/QuizList"),
  ...loadableOptions
});

const QuizDetails = Loadable({
  loader: () => import("../quiz-management/QuizDetails"),
  ...loadableOptions
});

const Questions = Loadable({
  loader: () => import("../quiz-management/Questions"),
  ...loadableOptions
});

const QuizSession = Loadable({
  loader: () => import("../quiz-session/QuizSession"),
  ...loadableOptions
});

const SessionList = Loadable({
  loader: () => import("../quiz-history/SessionList"),
  ...loadableOptions
});

const SessionViewer = Loadable({
  loader: () => import("../quiz-history/SessionViewer"),
  ...loadableOptions
});

const NotFound = Loadable({
  loader: () => import("./NotFound"),
  ...loadableOptions
});

export default class RouterOutput extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={SessionJoiner} />
        <Route exact path="/login" component={LogIn} />
        <Route
          exact
          path="/session/:sessionId"
          render={(props) => {
            return <QuizSession mode="student" sessionId={props.match.params.sessionId} />;
          }}
        />
        <ProtectedRoute exact path="/manage" component={QuizList} />
        <ProtectedRoute exact path="/manage/details" component={QuizDetails} />
        <ProtectedRoute
          exact
          path="/manage/details/:quizId"
          render={(props) => {
            return <QuizDetails quizId={props.match.params.quizId} />;
          }}
        />
        <ProtectedRoute
          exact
          path="/manage/questions/:quizId"
          render={(props) => {
            return <Questions quizId={props.match.params.quizId} />;
          }}
        />
        <ProtectedRoute
          exact
          path="/manage/session/:quizId"
          render={(props) => {
            return <QuizSession mode="instructor" quizId={props.match.params.quizId} />;
          }}
        />
        <ProtectedRoute
          exact
          path="/history/:sessionId"
          render={(props) => {
            return <SessionViewer sessionId={props.match.params.sessionId} />;
          }}
        />
        <ProtectedRoute
          exact
          path="/history/list/:quizId"
          render={(props) => {
            return <SessionList quizId={props.match.params.quizId} />;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
