import React from "react";
import { Loader, Message, Icon } from "semantic-ui-react";

export default class PageLoading extends React.Component {
  render() {
    const { error, pastDelay, timedOut } = this.props;
    if (error) {
      return (
        <Message error icon>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>Error</Message.Header>
            Uh oh, there was an error loading that page.
          </Message.Content>
        </Message>
      );
    } else if (timedOut) {
      return (
        <Message error icon>
          <Icon name="time" />
          <Message.Content>
            <Message.Header>Timeout</Message.Header>
            Looks like your internet connection is too slow to load that page in time. You can try
            again by refreshing the page.
          </Message.Content>
        </Message>
      );
    } else if (pastDelay) {
      return <Loader inline="centered">Page Loading</Loader>;
    } else {
      return null;
    }
  }
}
